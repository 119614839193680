/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@font-face {
  font-family: 'TEEE Semi';
  src: url('./fonts/TEEE-SemiLight.eot');
  src: url('./fonts/TEEE-SemiLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/TEEE-SemiLight.woff2') format('woff2'),
    url('./fonts/TEEE-SemiLight.woff') format('woff'),
    url('./fonts/TEEE-SemiLight.ttf') format('truetype'),
    url('./fonts/TEEE-SemiLight.svg#TEEE-SemiLight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TEEE';
  src: url('./fonts/TEEE-Bold.eot');
  src: url('./fonts/TEEE-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/TEEE-Bold.woff2') format('woff2'), url('./fonts/TEEE-Bold.woff') format('woff'),
    url('./fonts/TEEE-Bold.ttf') format('truetype'),
    url('./fonts/TEEE-Bold.svg#TEEE-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TEEE';
  src: url('./fonts/TEEE-Light.eot');
  src: url('./fonts/TEEE-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/TEEE-Light.woff2') format('woff2'), url('./fonts/TEEE-Light.woff') format('woff'),
    url('./fonts/TEEE-Light.ttf') format('truetype'),
    url('./fonts/TEEE-Light.svg#TEEE-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TEEE';
  src: url('./fonts/TEEE-Medium.eot');
  src: url('./fonts/TEEE-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/TEEE-Medium.woff2') format('woff2'), url('./fonts/TEEE-Medium.woff') format('woff'),
    url('./fonts/TEEE-Medium.ttf') format('truetype'),
    url('./fonts/TEEE-Medium.svg#TEEE-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TEEE';
  src: url('./fonts/TEEE-Regular.eot');
  src: url('./fonts/TEEE-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/TEEE-Regular.woff2') format('woff2'),
    url('./fonts/TEEE-Regular.woff') format('woff'),
    url('./fonts/TEEE-Regular.ttf') format('truetype'),
    url('./fonts/TEEE-Regular.svg#TEEE-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: TEEE, sans-serif;
}

@media screen and (max-width: 768px) {
  #root {
    overflow: scroll !important;
  }
}

a {
  color: #0000ee;
}

#CybotCookiebotDialogPoweredbyCybot{
  display: none!important;
}
